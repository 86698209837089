.image-shop{
    width:30%;
    transform: rotate(-90deg);
}
.h1-shop{
    color: #FA02FF;
}
.h4-shop{
    color: black;
    margin-bottom: 0px;
}
.p-shop{
    color: black;
    margin-top: 20px;
    margin-bottom: 0px;
    font-size: medium;
}
.email-shop{
    color: black;
    margin-bottom: 80px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: medium;
}
.at{
    color: #FA02FF;
    margin:0px;
    font-size: 50px;
}
.shop-button{
    background-color: #FA02FF;
    color:#01FF1A;
    opacity: 0.5;
    width:226px;
    height: 51px;
    font-size: x-large;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.color-text{
    color:red;
}