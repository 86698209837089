.image-gallery{
    width: 50%;
}
.h1-gallery{
    color: #FA02FF;
}
.p-gallery{
    color: black;
    margin-top: 0px;
    margin-bottom: 30px;
    font-size: medium;
}