.welcome-homepage{
    color:black;
    text-align: left;
    margin-left: 20px;
    margin-bottom: 0px;
    font-size: 18px;
}
.header-homepage{
    color: #FA02FF;
    font-size: 72px;
    margin: 30px 0px;
}
.website-homepage{
    color:black;
    text-align: right;
    margin-right: 20px;
    margin-bottom: 50px;
    font-size: 18px;
    height: 25px;
}
.hyperlink{
    color:black;
    text-align: left;
    margin-right: 20px;
    margin-bottom: 50px;
    font-size: 10px;
}
.bottom-text{
    display: flex;
}
.left{
    width:30%;
    padding:10px;
    font-size: 14px;
    color:black;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.left-header-homepage{
    color: #FA02FF;
    margin-top: 100px;
}
.shop-homepage{
    font-family: 'Ribeye', cursive;
    background-color: #01FF1A;
    color:#FA02FF;
    width:112px;
    height: 40px;
    font-size: 18px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.middle{
    width: 40%;
    font-size: 18px;
    color:black;
    height: 98%;
}
.names-homepage{
    display: flex
}
.frankie-homepage{
    font-family: 'Reenie Beanie', cursive;
    font-size: 24px;
}
.aggs-homepage{
    font-family: 'Rock Salt', cursive;
    font-size: 14px;
}
.chloet-homepage{
    font-family: 'Romanesco', cursive;
    font-size: 18px;
}
.sofia-homepage{
    font-family: 'Secular One', sans-serif;
    font-size: 18px;
}
.lottie-homepage{
    font-family: 'Miniver', cursive;
    font-size: 18px;
}
.cheers-homepage{
    color: #FA02FF;
}

.right{
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
a{
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
}
.bios-homepage{
    font-family: 'Ribeye', cursive;
    margin:auto;
    background-color: #01FF1A;
    color:#FA02FF;
    width:112px;
    height: 40px;
    font-size: 18px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.gallery-homepage{
    font-family: 'Ribeye', cursive;
    margin-top:auto;
    margin-left: 0px;
    padding: 0px 10px;

    background-color: #FA02FF;
    color:#01FF1A;
    width:112px;
    height: 40px;
    font-size: 18px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.multimedia-homepage{
    font-family: 'Ribeye', cursive;
    margin-bottom: auto;
    padding: 0px 10px;

    background-color: #FA02FF;
    color:#01FF1A;
    width:112px;
    height: 40px;
    font-size: 18px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
footer{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 14px;
}

@media (max-width: 700px){
    .header-homepage{
        font-size: 48px;
        margin:0px;
    }
    .website-homepage{
        margin:0px;
    }  
    .left{
        font-size: 6px;
        justify-content: space-between;
    }
    .shop-homepage{
        width:60px;
        height: 25px;
        font-size: 10px;
        margin-bottom: 120px;
    }
    .middle{
        font-size: 10px;
    }
    .names-homepage{
        display: flex
    }
    .frankie-homepage{
        font-family: 'Reenie Beanie', cursive;
        font-size: 16px;
    }
    .aggs-homepage{
        font-family: 'Rock Salt', cursive;
        font-size: 10px;
    }
    .chloet-homepage{
        font-family: 'Romanesco', cursive;
        font-size: 10px;
    }
    .sofia-homepage{
        font-family: 'Secular One', sans-serif;
        font-size: 10px;
    }
    .lottie-homepage{
        font-family: 'Miniver', cursive;
        font-size: 10px;
    }
    .right{
        margin-bottom: 120px;
    }
    .bios-homepage{
        width:70px;
        height: 25px;
        font-size: 10px;
    }
    .gallery-homepage{
        width:70px;
        height: 25px;
        font-size: 10px;
    }
    .multimedia-homepage{
        width:70px;
        height: 25px;
        font-size: 10px;
    }
    img{
        width:90%;
    }
    footer{
        font-size: 8px;
    }
  }