@import url(https://fonts.googleapis.com/css2?family=Ribeye&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Miniver&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Secular+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Romanesco&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rock+Salt&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Reenie+Beanie&display=swap);
/* main font - font-family: 'Ribeye', cursive; */
/* Lottie - font-family: 'Miniver', cursive; */
/* Sophia - font-family: 'Secular One', sans-serif; */
/* Chloe T - font-family: 'Romanesco', cursive; */
/* Aggs - font-family: 'Rock Salt', cursive; */
/* Frankie - font-family: 'Reenie Beanie', cursive; */


body {
  margin: 0;
  font-family: 'Ribeye', cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App {
  background-image: url(/static/media/10.540deb4b.jpg);
  background-repeat: repeat;
  background-size: 35%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.GreyBG{
  background-color: rgba(196, 196, 196,0.75);
  width: 500px;
}
@media (max-width: 700px){
  .GreyBG{
    width: 300px;
  }
}


.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.bio-header{
    color: #FA02FF;
}
.aggs-header{
    color: #24FF00;
    font-family: 'Rock Salt', cursive;
}
.chloet-header{
    color: #FA02FF;
    font-family: 'Romanesco', cursive;
}
.frankie-header{
    color: #FA02FF;
    font-family: 'Reenie Beanie', cursive;
}
.lottie-header{
    color: #FA02FF;
    font-family: 'Miniver', cursive;
}
.sofia-header{
    color: #24FF00;
    font-family: 'Secular One', sans-serif;
}
.headshots{
    width:90%;
    margin: auto;
}
.top-bios{
    display: flex;
    justify-content: space-evenly;
    width:80%;
    margin: auto;
}
.middle-bios{
    display: flex;
    justify-content: space-between;
    margin:20px 10px;
}
.bottom-bios{
    width: 186px;
    margin: auto;
    margin-bottom: 50px;
}
.image-bios{
    width: 70%;
    cursor: pointer;
}

.image-gallery{
    width: 50%;
}
.h1-gallery{
    color: #FA02FF;
}
.p-gallery{
    color: black;
    margin-top: 0px;
    margin-bottom: 30px;
    font-size: medium;
}
.welcome-homepage{
    color:black;
    text-align: left;
    margin-left: 20px;
    margin-bottom: 0px;
    font-size: 18px;
}
.header-homepage{
    color: #FA02FF;
    font-size: 72px;
    margin: 30px 0px;
}
.website-homepage{
    color:black;
    text-align: right;
    margin-right: 20px;
    margin-bottom: 50px;
    font-size: 18px;
    height: 25px;
}
.hyperlink{
    color:black;
    text-align: left;
    margin-right: 20px;
    margin-bottom: 50px;
    font-size: 10px;
}
.bottom-text{
    display: flex;
}
.left{
    width:30%;
    padding:10px;
    font-size: 14px;
    color:black;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.left-header-homepage{
    color: #FA02FF;
    margin-top: 100px;
}
.shop-homepage{
    font-family: 'Ribeye', cursive;
    background-color: #01FF1A;
    color:#FA02FF;
    width:112px;
    height: 40px;
    font-size: 18px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.middle{
    width: 40%;
    font-size: 18px;
    color:black;
    height: 98%;
}
.names-homepage{
    display: flex
}
.frankie-homepage{
    font-family: 'Reenie Beanie', cursive;
    font-size: 24px;
}
.aggs-homepage{
    font-family: 'Rock Salt', cursive;
    font-size: 14px;
}
.chloet-homepage{
    font-family: 'Romanesco', cursive;
    font-size: 18px;
}
.sofia-homepage{
    font-family: 'Secular One', sans-serif;
    font-size: 18px;
}
.lottie-homepage{
    font-family: 'Miniver', cursive;
    font-size: 18px;
}
.cheers-homepage{
    color: #FA02FF;
}

.right{
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
a{
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
}
.bios-homepage{
    font-family: 'Ribeye', cursive;
    margin:auto;
    background-color: #01FF1A;
    color:#FA02FF;
    width:112px;
    height: 40px;
    font-size: 18px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.gallery-homepage{
    font-family: 'Ribeye', cursive;
    margin-top:auto;
    margin-left: 0px;
    padding: 0px 10px;

    background-color: #FA02FF;
    color:#01FF1A;
    width:112px;
    height: 40px;
    font-size: 18px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.multimedia-homepage{
    font-family: 'Ribeye', cursive;
    margin-bottom: auto;
    padding: 0px 10px;

    background-color: #FA02FF;
    color:#01FF1A;
    width:112px;
    height: 40px;
    font-size: 18px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
footer{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 14px;
}

@media (max-width: 700px){
    .header-homepage{
        font-size: 48px;
        margin:0px;
    }
    .website-homepage{
        margin:0px;
    }  
    .left{
        font-size: 6px;
        justify-content: space-between;
    }
    .shop-homepage{
        width:60px;
        height: 25px;
        font-size: 10px;
        margin-bottom: 120px;
    }
    .middle{
        font-size: 10px;
    }
    .names-homepage{
        display: flex
    }
    .frankie-homepage{
        font-family: 'Reenie Beanie', cursive;
        font-size: 16px;
    }
    .aggs-homepage{
        font-family: 'Rock Salt', cursive;
        font-size: 10px;
    }
    .chloet-homepage{
        font-family: 'Romanesco', cursive;
        font-size: 10px;
    }
    .sofia-homepage{
        font-family: 'Secular One', sans-serif;
        font-size: 10px;
    }
    .lottie-homepage{
        font-family: 'Miniver', cursive;
        font-size: 10px;
    }
    .right{
        margin-bottom: 120px;
    }
    .bios-homepage{
        width:70px;
        height: 25px;
        font-size: 10px;
    }
    .gallery-homepage{
        width:70px;
        height: 25px;
        font-size: 10px;
    }
    .multimedia-homepage{
        width:70px;
        height: 25px;
        font-size: 10px;
    }
    img{
        width:90%;
    }
    footer{
        font-size: 8px;
    }
  }
.image-shop{
    width:30%;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
}
.h1-shop{
    color: #FA02FF;
}
.h4-shop{
    color: black;
    margin-bottom: 0px;
}
.p-shop{
    color: black;
    margin-top: 20px;
    margin-bottom: 0px;
    font-size: medium;
}
.email-shop{
    color: black;
    margin-bottom: 80px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: medium;
}
.at{
    color: #FA02FF;
    margin:0px;
    font-size: 50px;
}
.shop-button{
    background-color: #FA02FF;
    color:#01FF1A;
    opacity: 0.5;
    width:226px;
    height: 51px;
    font-size: x-large;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.color-text{
    color:red;
}
.rhap_container{
    max-width: 90%;
    margin: auto;
    margin-top: 25%;
    margin-bottom: 160px;
    border-radius: 20px;
    background: linear-gradient(135deg, #d7d6fa, #8e97f7);

}
.h1-multi{
    color: #01FF1A;
}
.rhap_container svg{
    background: linear-gradient(135deg, #ffffff, #9198e5);
    color: #5f68d4;
    border-radius: 100px;
}
.rhap_volume-indicator, .rhap_progress-indicator{
    background: linear-gradient(135deg, #ffffff, #9198e5);
}
.rhap_volume-controls svg{
    color: #5f68d4;
}
.rhap_progress-section{
    font-family: fantasy;
}
.rhap_main-controls button{
    height: 50px;
    width: 50px;
}
.rhap_additional-controls button{
    height: 50px;
    width: 50px;
}
.rhap_volume-container button {
    height: 50px;
    width: 50px;
}
.demo{
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: medium;
    text-decoration: none;
    justify-content: left;
}
