.bio-header{
    color: #FA02FF;
}
.aggs-header{
    color: #24FF00;
    font-family: 'Rock Salt', cursive;
}
.chloet-header{
    color: #FA02FF;
    font-family: 'Romanesco', cursive;
}
.frankie-header{
    color: #FA02FF;
    font-family: 'Reenie Beanie', cursive;
}
.lottie-header{
    color: #FA02FF;
    font-family: 'Miniver', cursive;
}
.sofia-header{
    color: #24FF00;
    font-family: 'Secular One', sans-serif;
}
.headshots{
    width:90%;
    margin: auto;
}
.top-bios{
    display: flex;
    justify-content: space-evenly;
    width:80%;
    margin: auto;
}
.middle-bios{
    display: flex;
    justify-content: space-between;
    margin:20px 10px;
}
.bottom-bios{
    width: 186px;
    margin: auto;
    margin-bottom: 50px;
}
.image-bios{
    width: 70%;
    cursor: pointer;
}
