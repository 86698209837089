@import url('https://fonts.googleapis.com/css2?family=Ribeye&display=swap');
/* main font - font-family: 'Ribeye', cursive; */
@import url('https://fonts.googleapis.com/css2?family=Miniver&display=swap');
/* Lottie - font-family: 'Miniver', cursive; */
@import url('https://fonts.googleapis.com/css2?family=Secular+One&display=swap');
/* Sophia - font-family: 'Secular One', sans-serif; */
@import url('https://fonts.googleapis.com/css2?family=Romanesco&display=swap');
/* Chloe T - font-family: 'Romanesco', cursive; */
@import url('https://fonts.googleapis.com/css2?family=Rock+Salt&display=swap');
/* Aggs - font-family: 'Rock Salt', cursive; */
@import url('https://fonts.googleapis.com/css2?family=Reenie+Beanie&display=swap');
/* Frankie - font-family: 'Reenie Beanie', cursive; */


body {
  margin: 0;
  font-family: 'Ribeye', cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
